import axios from 'axios';

const CACHE_DURATION = 15 * 60 * 1000; // 5 minutes in milliseconds

let cache = {};

async function getPrice(coinName) {
  // convert the coin name to lowercase and replace spaces with dashes
  const formattedCoinName = coinName.toLowerCase().replace(/ /g, '-');

  // check if the data is in the cache and if it's still fresh
  if (cache[formattedCoinName] && Date.now() - cache[formattedCoinName].timestamp < CACHE_DURATION) {
    return cache[formattedCoinName].data;
  }

  // if the data is not in the cache or it's stale, fetch it from the API
  try {
    const response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${formattedCoinName}&vs_currencies=usd`);
    const price = response.data[formattedCoinName].usd;
    // store the data in the cache
    cache[formattedCoinName] = {
      data: price,
      timestamp: Date.now()
    };
    return price;
  } catch (error) {
    console.error(error);
  }
}

export default getPrice;
