import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { updateData } from './utils';
import APY from './Apy';
import "./utils.css";

function Returns() {
  const [transactions, setTransactions] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    axios.get('./data.json')
      .then(res => {
        setTransactions(res.data);
      })
  }, []);

  useEffect(() => {
    updateData(transactions).then(data => {
      const updatedTotalValue = data.reduce((acc, transaction) => acc + transaction.totalValue, 0);
      setTotalValue(updatedTotalValue);
      const initialTotalValue = transactions.reduce((acc, transaction) => acc + transaction.valueUSD, 0);
      setDifference(updatedTotalValue - initialTotalValue);
    });
  }, [transactions]);

  return (
    <div>
     {difference.toFixed(0)}
    </div>
  );
}

export default Returns;
