import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { updateData } from './utils';
import './table.css';
import TotalWorth from './TotalWorth';

function Portfolio() {
  const [transactions, setTransactions] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const totalWorth = TotalWorth(portfolio);

  useEffect(() => {
    axios.get('./data.json')
      .then(res => {
        setTransactions(res.data);
      })
  }, []);

  useEffect(() => {
    updateData(transactions).then(data => {
      // group the transactions by coin name and calculate the total amount and value for each coin
      const groupedTransactions = data.reduce((acc, transaction) => {
        if (acc[transaction.coinName]) {
          acc[transaction.coinName].totalAmount += Number(transaction.amount);
          acc[transaction.coinName].totalValue += Number(transaction.totalValue);
        } else {
          acc[transaction.coinName] = {
            coinName: transaction.coinName,
            ticker: transaction.ticker,
            totalAmount: Number(transaction.amount),
            totalValue: Number(transaction.totalValue),
          }
        }
        return acc;
      }, {});
      let portfolio = Object.values(groupedTransactions);
      //sort the transactions by totalValue descending
      portfolio.sort((a,b)=>b.totalValue-a.totalValue)
      setPortfolio(portfolio);
    });
  }, [transactions]);

  return (
    <div className='membersdiv'>
     
    <table>
      <thead>
        <tr className='tabletop'>
          <th>Coin</th>
          <th>Amount</th>
          <th>Value USD</th>
          <th>% Total</th>
        </tr>
      </thead>
      <tbody>
        {portfolio.map(transaction => (
          <tr key={transaction.coinName} className='tabledown'>
            <td>{transaction.ticker}</td>
            <td>{transaction.totalAmount}</td>
            <td>{transaction.totalValue && transaction.totalValue.toFixed(2)}</td>
            <td>{((transaction.totalValue / totalWorth) * 100).toFixed(2)}%</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
}

export default Portfolio;