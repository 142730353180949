import React, { useState, useEffect } from 'react';
import TotalWorth from './TotalWorth';
import TotalShares from './TotalShares';

const PricePerShare = () => {
  const [pricePerShare, setPricePerShare] = useState(0);
  const totalWorth = parseInt(TotalWorth());
  const totalShares = parseInt(TotalShares());

  useEffect(() => {
    if (totalShares && totalWorth) {
      setPricePerShare(totalWorth / totalShares);
    }
  }, [totalShares, totalWorth]);

  return pricePerShare.toFixed(2);
}

export default PricePerShare;
