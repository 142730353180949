import React, { useState, useEffect } from 'react';
import { updateData } from './utils';
import axios from 'axios';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip } from 'recharts';

function PortfolioHistory() {
const [transactions, setTransactions] = useState([]);
const [portfolioHistory, setPortfolioHistory] = useState([]);

useEffect(() => {
axios.get('./data.json')
.then(res => {
setTransactions(res.data);
})
}, []);

useEffect(() => {
updateData(transactions).then(data => {
const initialTotalValue = 0;
const history = data.reduce((acc, transaction) => {
const date = new Date(transaction.date);
const formattedDate = date.toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});

acc.push({
date: formattedDate,
totalValue: acc[acc.length - 1].totalValue + transaction.totalValue,
transactionType: transaction.transactionType,
coinName: transaction.coinName,
amount: transaction.amount,
fundValue: transaction.fundValue
});
return acc;
}, [{ date: '', totalValue: initialTotalValue }]);

setPortfolioHistory(history);
});
}, [transactions]);


function CustomTooltip({ payload, active }) {
    if (active && payload && payload.length > 0) {
      const transaction = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="label">{transaction.date}</p>
          <p className="intro">{transaction.transactionType}</p>
          <p className="desc">{transaction.coinName}</p>
          <p className="desc">{transaction.amount}</p>
          <p className="desc">{transaction.fundValue}</p>
        </div>
      );
    }
  
    return null;
  }

return (
<LineChart width={800} height={400} data={portfolioHistory}>
<XAxis dataKey="date" />
<YAxis />
<CartesianGrid stroke="#eee" opacity="0.2" strokeDasharray="3 3" />
<Line type="monotone" dataKey="totalValue" stroke="#8884d8" />
<Tooltip content={<CustomTooltip />}/>
</LineChart>
);
}

export default PortfolioHistory;