import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { updateData } from './utils';
import './table.css';

function NeonList() {
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    axios.get('./data.json')
      .then(res => {
        setData(res.data);
      })
  }, []);

  useEffect(() => {
    updateData(data).then(newData => {
      setUpdatedData(newData);
    });
  }, [data]);

  return (
    <div className='membersdiv'>
    
    <table>
      <thead>
        <tr className='transactiontext'>
          <th>Type</th>
          <th>Coin Name</th>
          <th>Amount</th>
          <th>Deal Price</th>
          <th>Deal Value</th>
          <th>Date</th>
          <th>Current Price</th>
          <th>Current Value </th>
          <th>Change USD</th>
          <th>Change %</th>
        </tr>
      </thead>
      
      <tbody>
        {updatedData.map(transaction => (
          <tr key={transaction.id} className='tabledown'>
            <td>{transaction.transactionType}</td>
            <td>{transaction.ticker}</td>
            <td>{transaction.amount}</td>
            <td>{transaction.currentPrice.toFixed(2)}</td>
            <td>{transaction.valueUSD.toFixed(2)}</td>
            <td>{transaction.formattedDate}</td>
            <td>{transaction.price.toFixed(2)}</td>
            <td>{transaction.totalValue.toFixed(2)}</td>
            <td>{transaction.differenceInUSD.toFixed(2)}</td>
            <td>{transaction.differenceInPercentage.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
}

export default NeonList;
