import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PricePerShare from './PricePerShare';
import TotalShares from './TotalShares';
import './table.css';

const MemberShares = () => {
  const [members, setMembers] = useState([]);
  const pricePerShare = PricePerShare();
  const [totalShares, setTotalShares] = useState(0);
  const [totalWorth, setTotalWorth] = useState(0);

  useEffect(() => {
    axios.get('./members.json')
      .then(res => {
        // Sort the members by their shares
        const sortedMembers = res.data.members.sort((a, b) => {
          const memberSharesA = a.transactions.reduce((acc, transaction) => acc + transaction.shares, 0);
          const memberSharesB = b.transactions.reduce((acc, transaction) => acc + transaction.shares, 0);
          return memberSharesB - memberSharesA;
        });
        setMembers(sortedMembers);
        // calculate the total shares
        const total = res.data.members.reduce((acc, member) => {
          member.transactions.forEach(transaction => acc += transaction.shares);
          return acc;
        }, 0);
        setTotalShares(total);
        // calculate the total worth
        const totalWorth = res.data.members.reduce((acc, member) => {
          const memberShares = member.transactions.reduce((acc, transaction) => acc + transaction.shares, 0);
          const worth = memberShares * pricePerShare;
          return acc + worth;
        }, 0);
        setTotalWorth(totalWorth);
      })
  }, []);

  return (
    <div className='membersdiv'>
      
       <table>
        <thead>
          <tr className='tabletop'>
            <th>User</th>
            <th>Shares</th>
            <th>Worth</th>
            <th>% Fund</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => {
            const memberShares = member.transactions.reduce((acc, transaction) => acc + transaction.shares, 0);
            const worth = memberShares * pricePerShare;
            // calculate the percentage of ownership
            const percentage = (memberShares / totalShares) * 100;
            return (
              <tr key={index} className='tabledown' >
                <td>{member.name}</td>
                <td>{memberShares}</td>
                <td>{worth.toFixed(2)}</td>
                <td>{percentage.toFixed(2)}%</td>
              </tr>
          );
        })}
      </tbody>
    </table>
    <div className='members-bot'> 
     <div className='left'>Members: 4</div>  <div className='right'>TotalShares <TotalShares/></div>
    </div>
    </div>
  );
}

export default MemberShares;
