import React, { useState } from 'react';
import bcrypt from 'bcryptjs';
import Dash from './components/dash';
import './App.scss';

const salt = bcrypt.genSaltSync(10);
const hash = bcrypt.hashSync("test123", salt);

const App = () => {
const [loggedIn, setLoggedIn] = useState(false);
const [loginAttempts, setLoginAttempts] = useState(0);

const handleLogin = async (event) => {
event.preventDefault();

// Get the user-provided password from the form
const password = event.target.elements.password.value;
// Hash the password from the .env file for comparison
const passwordMatch = await bcrypt.compare(password, hash);

if (passwordMatch) {
  setLoggedIn(true);
} else {
  setLoginAttempts(loginAttempts + 1);
  if (loginAttempts >= 5) {
    alert('Too many login attempts, please try again later.');
  } else {
    alert('Incorrect password, please try again.');
  }
}
};

return (
<div className='dash1'>
{loggedIn ? (
<Dash />
) : (
<form onSubmit={handleLogin}>
<label>
Password:
<input type='password' name='password' />
</label>
<button type='submit'>Login</button>
</form>
)}
</div>
);
}

export default App;
