import { Alchemy, Network } from 'alchemy-sdk';
import React, { useState, useEffect } from 'react';
import getPrice from './getPrice';
import './utils.css';
import './RetriveNfts.css';

const settings = {
apiKey: 'U1A6YlG-lFA6lTXt_Gl6PfOOXCRkbkkQ',
network: Network.ETH_MAINNET
};
const alchemy = new Alchemy(settings);

const Main2 = () => {
let [nfts, setNfts] = useState([]);
let [ethPrice, setEthPrice] = useState(0);
  
useEffect(() => {
  alchemy.nft.getNftsForOwner('0x757210Acc4Cca94568Ab4C698Cb14Aed3F490224').then(nfts => {
    setNfts(nfts);
    console.log(nfts.ownedNfts[0].media[0].gateway);
  });

  getPrice('Ethereum').then(price => {
    setEthPrice(price);
  });
}, []);

const calculateTotalFloorPrice = () => {
  if (nfts && nfts.ownedNfts) {
    return (nfts.ownedNfts.reduce((total, nft) => {
      return total + nft.contract.openSea.floorPrice;
    }, 0) * ethPrice).toFixed(2);
  }
  return 0;
}

return (
  <div className='nftcarousel'>
    <p className='total-floorprice'>Minimum Floor Price: {calculateTotalFloorPrice()} USD (exluded from total) </p>
    {nfts && nfts.ownedNfts && nfts.ownedNfts.map(nft => (
      <div className='nftcard' style={{ backgroundImage: `url(${nft.media[0].gateway})`, objectFit: "cover", width: "200px", height: "300px",}}>
        <div className='nftcard-text'>
          <p className='collname'> {nft.contract.openSea.collectionName}</p>
          <p className='floorprice'>Floor Price: {nft.contract.openSea.floorPrice} ETH</p>
        </div>
      </div>
    ))}
    
  </div>
);
}

export default Main2;
