import React, { useState, useEffect } from 'react';
import { updateData } from './utils';
import axios from 'axios';

const TotalWorth = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalWorth, setTotalWorth] = useState(0);

  useEffect(() => {
    axios.get('./data.json')
      .then(res => {
        setTransactions(res.data);
      })
  }, []);

  useEffect(() => {
    updateData(transactions)
      .then(data => {
        // calculate the total value of all transactions
        const totalValue = data.reduce((acc, transaction) => {
          acc += parseFloat(transaction.totalValue);
          return acc;
        }, 0);
        setTotalWorth(totalValue);
      });
  }, [transactions]);

  return totalWorth.toFixed(0.0)
}

export default TotalWorth;

