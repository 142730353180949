import axios from 'axios';
import getPrice from './getPrice';

export async function updateData(data) {
  try {
    const newData = await Promise.all(data.map(async transaction => {
      const price = await getPrice(transaction.coinName);
      const totalValue = price * transaction.amount;
      const differenceInUSD = totalValue - transaction.valueUSD;
      const differenceInPercentage = (differenceInUSD / transaction.valueUSD) * 100;
      const date = new Date(transaction.date);
      const formattedDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      return {
        ...transaction,
        price,
        totalValue,
        differenceInUSD,
        differenceInPercentage,
        formattedDate
      };
    }));
    return newData;
  } catch (error) {
    console.error(error);
  }
}
