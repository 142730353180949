import React, { useEffect, useState } from 'react';
import { updateData } from './utils';
import axios from 'axios';

function APY() {
const [transactions, setTransactions] = useState([]);
const [apy, setAPY] = useState(0);

useEffect(() => {
axios.get('./data.json')
.then(res => {
setTransactions(res.data);
})
}, []);

useEffect(() => {
updateData(transactions).then(data => {
// calculate the total amount invested and the current total value of all transactions
const totalAmountInvested = data.reduce((acc, transaction) => acc + transaction.valueUSD, 0);
const currentTotalValue = data.reduce((acc, transaction) => acc + transaction.totalValue, 0);
// calculate the APY as the difference between the current total value and the total amount invested, divided by the total amount invested, multiplied by 100
const apy = ((currentTotalValue - totalAmountInvested) / totalAmountInvested) * 100;
setAPY(apy);
});
}, [transactions]);

return (
<div>
{apy.toFixed(2)}%
</div>
);
}

export default APY;