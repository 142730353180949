import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TotalShares = () => {
  const [members, setMembers] = useState([]);
  const [totalShares, setTotalShares] = useState(0);

  useEffect(() => {
    axios.get('./members.json')
      .then(res => {
        setMembers(res.data.members);
      })
  }, []);

  useEffect(() => {
    // calculate the total shares
    const total = members.reduce((acc, member) => {
      member.transactions.forEach(transaction => acc += transaction.shares)
      return acc;
    }, 0);
    setTotalShares(total);
  }, [members]);

  return totalShares;
}

export default TotalShares;
