import React from 'react'
import './dash.css';
import './table.css';
import './scroll.css';
import APY from './Apy';
import Returns from './Return';
import TotalWorth from './TotalWorth';
import Portfolio from './portfolio';
import PortfolioHistory from './portfoliohistory';
import NeonList from './NeoList';
import TotalShares from './TotalShares';
import PricePerShare from './PricePerShare';
import Members from './members';
import RetriveNfts from './RetriveNfts';
import CollectionNFTs from './RetriveNfts';
import NFTList from './RetriveNfts';
import Home from './RetriveNfts';
import Main2 from './alchemytest';


const Dash = () => {
  return (
        

   <main class="main">

    <div class="main-news"><div class="scroll-left">
<h1>25 ventures is now in closed alpha</h1>
</div></div>

<div className='statsintro'>Fund 25 Overview</div> 

      
     <div class="main-overview">
            <div class="overviewcard">           
            <div className='overtext'><TotalWorth /></div>
            <div className='undertext'>Fund Worth</div>
            </div>

            <div class="overviewcard">
            <div>
            <div className='overtext'><Returns /></div>
            <div className='undertext'>Total Return</div>
           </div>
              
           </div>

           <div class="overviewcard">           
            <div className='overtext'><APY/></div>
            <div className='undertext'>APY</div>
            </div>

            <div class="overviewcard">           
            <div className='overtext'><PricePerShare /></div>
            <div className='undertext'>Share Price</div>
            </div>

          </div>
      
        <div class="graphic"><PortfolioHistory /></div>

     
     
     <div class="main-cards">
        <div>
        <div className='portfoliointro'>Portfolio</div> 
        <div class="portfolio"><Portfolio /></div>
        </div>
        <div>
        <div className='membersintro'>Members</div> 
        <div class="members"><Members /> </div>
        </div>

        
       
     </div>
        <div>
        <div className='transactionintro'>Transactions</div> 
        <div class="transactions"><NeonList /></div>
        </div>
     
        <div>
        <div className='transactionintro'>FUND 25 NFTS</div> 
        <div >
          <Main2 /></div>
        </div>

     

        <div className='version'> 25ventures : Alpha.0.0.2</div> 
     

   </main>
 

  )
}

export default Dash

